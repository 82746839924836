export const USER_PERMISSIONS = {
    GUEST: 'guest',
    CANDIDATE: 'candidate',
    HR_PORTAL: 'hr_portal',
    HR: 'hr',
    COLLABORATION: 'collaboration',
    EDIT_COMPANY: 'edit_company',
};

export const APPLICATION_STAGES = {
    SOURCED: '10_sourced',
    APPLIED: '20_applied',
    PHONE_SCREEN: '30_phone_screen',
    ASSESSMENT: '40_assessment',
    OFFER: '60_offer',
    HIRED: '70_hired',
    REJECTED: '90_rejected'
};

export const APPLICATION_STAGE_LABELS = {
    [APPLICATION_STAGES.SOURCED]: 'Sourced',
    [APPLICATION_STAGES.APPLIED]: 'Applied',
    [APPLICATION_STAGES.PHONE_SCREEN]: 'Phone Screen',
    [APPLICATION_STAGES.ASSESSMENT]: 'Assessment',
    [APPLICATION_STAGES.OFFER]: 'Offer',
    [APPLICATION_STAGES.HIRED]: 'Hired',
    [APPLICATION_STAGES.REJECTED]: 'Rejected'
};
